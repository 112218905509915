import { Component, Inject, ChangeDetectorRef, ElementRef, ViewChild, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api.service'
import { ActionService,AuthService } from 'src/app/core';

@Component({
  selector: 'app-dialog-custom',
  templateUrl: './dialog-custom.component.html',
  styleUrls: ['./dialog-custom.component.scss']
})
export class DialogCustomComponent {
  public messageSentEvent: EventEmitter<void> = new EventEmitter<void>();
  messages: {employe:string, id_comment: number, message: string, createdAt: string }[] = [];
  newMessage: string = '';
  idQuotation: number;
  @ViewChild('chatContainer') chatContainer: ElementRef;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: { id: number, messages: {employe:string ,id_comment: number, message: string, createdAt: string }[] }, private api: ApiService, private cdr: ChangeDetectorRef,private action: ActionService,public auth: AuthService) {
    this.messages = data.messages;
    this.idQuotation = data.id;

  }
  async sendMessage() {
  if (this.newMessage.trim() !== '') {
    try {
      const response = await this.api.saveMessage(this.idQuotation, this.newMessage, this.auth.session.user.name);
      this.messages.push({ employe: this.auth.session.user.name, id_comment: response['0'].id_comment, message: this.newMessage, createdAt: response['0'].createdAt });
      this.newMessage = '';
      this.cdr.markForCheck();
      this.scrollToBottom();
    } catch (error) {
      this.action.error2('Error al enviar el mensaje');
    }
  }
}
scrollToBottom() {
  if (this.chatContainer && this.chatContainer.nativeElement.scrollHeight > this.chatContainer.nativeElement.clientHeight) {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    }, 0);
  }
}

  deleteMessage(id: number) {
  // this.api.deleteMessage(id);
   // this.messages = this.messages.filter(message => message.id_comment !== id);
   // this.cdr.detectChanges(); 
  }
}

/*
  deleteMessage(id: number) {
    this.api.deleteMessage(id).subscribe(
      (response) => {
        this.messages = this.messages.filter(message => message.id_comment !== id);
        this.cdr.detectChanges(); 
      },
      (error) => {
        this.action.error2('Error al intentar eliminar el mensaje:');
      }
    );
  } */

  /*
  sendMessage() {
    if (this.newMessage.trim() !== '') {
      this.api.saveMessage(this.idQuotation, this.newMessage,this.auth.session.user.id).subscribe((response: any)=> {
        this.messages.push({employe :this.auth.session.user.name,id_comment: response.id_comment, message: this.newMessage, createdAt: response.createdAt });
        this.newMessage = ''; 
        this.cdr.markForCheck();
        this.scrollToBottom();
      }, error => {
        this.action.error2('Error al enviar el mensaje');
      //  this.messageSentEvent.emit(); //
      });
    }
  } */