import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-quotation-detail',
  templateUrl: './quotation-detail.component.html',
  styleUrls: ['./quotation-detail.component.scss']
})
export class QuotationDetailComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
