import { Component, OnInit} from '@angular/core';
import { QuotationService, ApiService, Quotation, ActionService,AuthService } from 'src/app/core';
import { AppStorage, StorageKey } from 'src/app/shared';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-quotations',
  templateUrl: './user-quotations.component.html',
  styleUrls: ['./user-quotations.component.scss']
})
export class UserQuotationsComponent implements OnInit {
  quotationQuantities = 0;
  lastOffset = 0;
  userQuotations: Quotation[] = [];
  isLoading = false;
  isFullyLoaded = false;
  pagination = [];
  paginationSets = [];
  currentPaginationDisplay = [];
  showMenu: boolean = false;  //nuevo
  fechaDesde:string;
  fechaHasta:string;
  idquotation:number;
  selectedCurrency: string = "Todas"; //
  selectedPropietario: string = "Todos"; //
  selectedEstatus: string = "Todos"; //
  backupUserQuotations: any[];
  k: any[];
  backu: any[];
  company_name :string ='';
  public mostrarOtroBoton: boolean = false;
  sortOrder: string = 'desc';
  suscription : Subscription;
  
  constructor( public quotationService: QuotationService, private api: ApiService, private action: ActionService,private datePipe: DatePipe,private dialogService:DialogService,private cdr: ChangeDetectorRef,public auth: AuthService) { }
 
  toggleMenu() {
    this.showMenu = !this.showMenu;
}

openDialogCustom($comments){
  this.dialogService.openDialogCustom($comments);
}
  //filtrado por el nombre de la compañia
  applyFilters(companyName: string, minAmount: string, maxAmount: string, currency: string, fechaDesde: string, fechaHasta: string, propietario: string,idquotation:number,estatus:string) {
    if (!this.backupUserQuotations) {
      this.backupUserQuotations = [...this.k];
      this.backu = [...this.userQuotations];
    }
    let filteredQuotations = this.backupUserQuotations;

    //filtrados por nombre compañia
    if (companyName) {
      filteredQuotations = filteredQuotations.filter(quotation => quotation.company_name.toLowerCase().includes(companyName.toLowerCase()));
    }

    if(idquotation){
      filteredQuotations = filteredQuotations.filter(quotation => quotation.id_cotizr_quotation == idquotation);
    }

    //filtrado por Montos
    const min = parseFloat(minAmount);
    const max = parseFloat(maxAmount);
    if (!isNaN(min) && !isNaN(max)) {
      filteredQuotations = filteredQuotations.filter(quotation => quotation.subtotal >= min && quotation.subtotal <= max);
    } else if (!isNaN(min)) {
      filteredQuotations = filteredQuotations.filter(quotation => quotation.subtotal <= min);
    } else if (!isNaN(max)) {
      filteredQuotations = filteredQuotations.filter(quotation => quotation.subtotal >= max);
    }

    //filtrado por Divisa
    if (currency === 'USD') {
      filteredQuotations = filteredQuotations.filter(quotation => quotation.currency === 'usd');
    } else if (currency === 'VES') {
      filteredQuotations = filteredQuotations.filter(quotation => quotation.currency === 'bs');
    }

    //filtrado por Propietario
    if (propietario !== 'Todos') {
      console.log(propietario);
      filteredQuotations = filteredQuotations.filter(quotation => quotation.user.name === propietario);
    }

    if (estatus !== 'Todos') {
      filteredQuotations = filteredQuotations.filter(quotation => quotation.status === parseInt(estatus));
    }

    //filtrado por fechas
    if (fechaDesde && fechaHasta) {
      const startDate = new Date(fechaDesde);
      const endDate = new Date(fechaHasta);
      filteredQuotations = filteredQuotations.filter(quotation => {
        const formattedDate = this.datePipe.transform(quotation.date_created, 'yyyy-MM-dd') || '';
        const fechaQuotation = new Date(formattedDate);
        return fechaQuotation >= startDate && fechaQuotation <= endDate;
      });
    }

    if(currency=='Todas' && !companyName && !fechaDesde && !fechaHasta && !minAmount && !maxAmount && propietario=='Todos' && !idquotation && estatus=='Todos'){
      this.userQuotations=this.backu;
      this.requestOffset(0)
    }else{
      this.userQuotations = filteredQuotations;
    }
}
sortByTotal(order: string) {
  this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
  if (order === 'asc') {
    this.userQuotations.sort((a, b) => a.subtotal - b.subtotal);
  } else {
    this.userQuotations.sort((a, b) => b.subtotal - a.subtotal);
  }
}

ids(cotizacion,val){
  this.action.success('Estatus Actualizado');
  this.api.updateQuotationStatus(cotizacion,val);
}

resetFilters() {
  this.selectedPropietario = 'Todos';
  this.selectedCurrency = 'Todas';
  this.company_name = '';
  this.fechaDesde = '';
  this.fechaHasta = '';
  this.requestOffset(0)
  this.userQuotations =  this.backu
}
ngOnInit() {
  this.requestOffset(0);/*
  this.dialogService.messageSentEvent.subscribe(() => {
    this.requestOffset(this.lastOffset); 
  });*/
//this.suscription = this.dialogService.refresh$.subscribe(()=>{
//this.requestOffset(this.lastOffset);
//})
}

/*
ngOnDestroy():void{
this.suscription.unsubscribe();
}*/
async requestOffset(offset: number) {
  this.action.load('Descargando lista de cotizaciones');
  this.isLoading = true;
  this.userQuotations = [];
  this.lastOffset = offset;
  const quotationObj = await this.api.getUserQuotations(undefined, offset);
  const quotations = quotationObj.quotations;
  this.quotationQuantities = quotationObj.total;
  
  if (quotations) {
    this.action.stop();
    quotations.map((x) => {
      if (x.items.length) {
        x.status = parseInt(x.status, 0);
        x.items = JSON.parse(x.items);
        x.user = JSON.parse(x.user);
      }
      return x;
    });
    
      this.k=quotations;
      this.userQuotations = quotations.slice(0, 50); // Mostrar solo las primeras 10 cotizaciones
      
      if (offset === 0) {
          this.displayPages(10);
          this.currentPaginationDisplay = this.paginationSets[0];
          this.isFullyLoaded = true;
      }
      
      this.isLoading = false;
  } else {
      this.action.stop();
      this.isLoading = false;
  }
}
displayPages(groupNumber: number): void {
  this.pagination = [];
  const pageNumber = Math.ceil(this.quotationQuantities / 10);

  for (let i = 1; i <= pageNumber; i++) {
      const offset = (i - 1) * 10;
      const isCurrentPage = i === groupNumber;

      this.pagination.push({
          number: i,
          offset: offset,
          isActive: isCurrentPage 
      });
  }
  // Limpiar paginationSets antes de actualizarlo
  this.paginationSets = [];

  // Agrupar las páginas en sets
  for (let i = 0; i < this.pagination.length; i += groupNumber) {
      this.paginationSets.push(this.pagination.slice(i, i + groupNumber));
  }
}
previousPaginationDisplay(currentPaginationDisplay) {
  const index = this.paginationSets.indexOf(this.currentPaginationDisplay);
  this.currentPaginationDisplay = this.paginationSets[index - 1];
}
nextPaginationDisplay(currentPaginationDisplay) {
  const index = this.paginationSets.indexOf(this.currentPaginationDisplay);
  this.currentPaginationDisplay = this.paginationSets[index + 1];
}
download(quotation) {
  this.quotationService.generatePDF(quotation);
}
  
}
