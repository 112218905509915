import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  BusinessMath,
  PrestashopInfo,
  AppStorage,
  StorageKey,
  QuotationState,
  DevEnv,
  ResponseStatus,
  AuthType,
  QuotationStateInfo
} from 'src/app/shared';
import { QuotationItem, ApiService, QuotationService, Quotation, ActionService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  isFormValid = false;
  refQuotation: Quotation = new Quotation();
  businessMath = BusinessMath;
  quotationItems: QuotationItem[] = [];
  onlyProductsQuotation = false;
  loadingDolarRate = false;
  //discountValue: number = 0;
  titulo: string = '';
  precio: string = '';

  statusButtons = [
    { name: QuotationStateInfo[QuotationState.Pendent].message, status: QuotationState.Pendent },
    { name: QuotationStateInfo[QuotationState.Delivered].message, status: QuotationState.Delivered },
    { name: QuotationStateInfo[QuotationState.Rejected].message, status: QuotationState.Rejected },
    { name: QuotationStateInfo[QuotationState.Approved].message, status: QuotationState.Approved },
  ];

  currencyButtons = [
    { name: 'Dólares (USD)', value: 'usd'},
    { name: 'Bolívares (Bs)', value: 'bs'}
  ];

  ivaButtons = [
    { name: 'IVA Normal', value: 'normal'},
    { name: 'Sin Desglose', value: 'include' },
    { name: 'Sin IVA', value: 'none' }
  ];

  public quotation: FormGroup = new FormGroup({
    receiver: new FormControl(' ', Validators.required),
    company_name: new FormControl(' ', Validators.required),
    company_address: new FormControl(' ', Validators.required),
    identification: new FormControl(' ', Validators.required),
    phone_number: new FormControl(' ', Validators.required),
    status: new FormControl(0),
    currency: new FormControl('usd', Validators.required),
    dolar_rate: new FormControl(1, Validators.required),
    iva_type: new FormControl('normal', Validators.required),
    discount: new FormControl(0, Validators.required),
    });

  @Output() quotationValue: EventEmitter<any> = new EventEmitter();
  @Output() valid: EventEmitter<boolean> = new EventEmitter();
  @Input() disableAll: boolean;

  @Input() type: 'quotation' | 'generate';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    public quotationService: QuotationService,
    private action: ActionService) {
    this.refQuotation.status = QuotationState.Pendent;
  }

  async ngOnInit() {
    this.getDolarRate();
    this.quotationService.quotationItems = [];
  //  console.log('this.type :>> ', this.type);
  if (this.type === 'quotation') {
    this.route.params.subscribe(async (params) => {
      const id = params['id'];
      if (id) {
        try {
          const quotation = AppStorage.get(StorageKey.Session).type === AuthType.Employee ?
            await this.api.getQuotationAsAdmin(id) : await this.api.getQuotation(id);
          
          if (quotation && quotation.quotations && quotation.quotations.length > 0) {
            const quotationItems = JSON.parse(quotation.quotations[0].items);
            const itemsWithTotal = quotationItems.map(item => ({ ...item, total: item.price * item.amount }));
  
            this.refQuotation = quotation.quotations[0];
            const model = new Quotation(quotation.quotations[0]);
            this.quotationService.quotationItems = itemsWithTotal;
  
            setTimeout(() => {
              this.action.stop();
              this.quotation.patchValue(model);
            }, 0);
          } else {
            this.action.stop();
            this.action.error('Lo siento no estas autorizado para ver esta cotizacion', 400);
          }
        } catch (err) {
          console.error('Error al procesar la cotización:', err);
          this.action.stop();
        }
      }
    });
  }

    this.quotation.controls['dolar_rate'].valueChanges.subscribe(v => {
      const newValue = Number(v) || 1;
      if (!v) {
        this.quotation.controls['dolar_rate'].setValue(newValue);
      }
      AppStorage.set(StorageKey.DolarRate, newValue);
    });
  }

  get f() {
    return this.quotation.controls;
  }

  isOnline(): boolean {
    return navigator.onLine;
  }

  getDolarRate() {
    if (AppStorage.has(StorageKey.DolarRate)) {
      this.quotation.patchValue({ dolar_rate: AppStorage.get(StorageKey.DolarRate)});
    } else {
      this.syncDolarRate();
    }
  }

  async syncDolarRate(e = null) {
    try {
      e.preventDefault();
      this.loadingDolarRate = true;
      const dr = await this.api.getDolarRate();
      AppStorage.set(StorageKey.DolarRate, dr.USD.dolartoday);
      this.quotation.patchValue({ dolar_rate: dr.USD.dolartoday });
      this.loadingDolarRate = false;
    } catch (err) {
      this.loadingDolarRate = false;
    }
  }

  createQuotationObject(formValues, items): Quotation {
    const session = AppStorage.get(StorageKey.Session);
    const form = formValues;
    const quotation = new Quotation({
      items: items,
      status: QuotationState.Pendent,
      user: session.user,
      id_customer: session.user.id,
      user_type: session.type,
      company_address: form.company_address,
      company_name: form.company_name,
      receiver: form.receiver,
      phone_number: form.phone_number,
      identification: form.identification,
      emails: [],
      subtotal: BusinessMath.subtotalFromProductArray(items, form.currency, form.dolar_rate),
      currency: form.currency,
      dolar_rate: form.dolar_rate,
      iva_type: form.iva_type,
   //   discount: this.discountValue,
      discount: form.discount
  });
    return quotation;
   
  }

  async createQuotation(downloadOnly?: boolean) {
    this.action.load('Creando cotizacion');
    DevEnv.print('createQuotation(): Creating Quotation');
    Object.keys(this.quotation.value).forEach(key => {
      if (!this.quotation.value[key] && key !== 'status') {
        this.quotation.value[key] = ' ';
      }
    });
    const quotation = this.createQuotationObject(this.quotation.value, this.quotationService.quotationItems);
    if (!downloadOnly) {
      const isQuotationCreated = await this.api.createQuotation(quotation.toDBObject(true));
      if (isQuotationCreated) {
        const newQuotation = (await this.api.getUserQuotations(1)).quotations[0];
        if (newQuotation) {
          newQuotation.items = JSON.parse(newQuotation.items).map(x => Object.assign(x, { total: x.price * x.amount }));
          newQuotation.user = JSON.parse(newQuotation.user);
          this.download(new Quotation(newQuotation));
          this.action.stop();
          this.reset();
        } else {
          this.action.stop();
        }
      } else {
        this.action.stop();
      }
    } else {
      quotation.id_cotizr_quotation = 'OFFLINE';
      this.action.stop();
      this.download(new Quotation(quotation));
    }
  }

   generarID(): string {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * caracteres.length);
      id += caracteres.charAt(randomIndex);
    }
  
    return id;
  }
  

  agregarProducto() {
    const nuevoProducto = {
      id_product: this.generarID(), // Generar ID aleatorio de 5 dígitos
      name: this.titulo,
      price: parseFloat(this.precio),
      ammount: 1,
      total: 0,
      description: '',
      id_default_image: '2345'
    };
    const nuevoItem: QuotationItem = {
      id_product: nuevoProducto.id_product, 
      name: nuevoProducto.name,
      price: nuevoProducto.price,
      ammount: nuevoProducto.ammount,
      total: nuevoProducto.total,
      description: nuevoProducto.description,
      id_default_image: nuevoProducto.id_default_image.toString() 
    };
  
    this.quotationService.quotationItems.push(nuevoItem);
  }
  async updateQuotation() {
    const session = AppStorage.get(StorageKey.Session);
    this.action.load('Actualizando Cotizacion');
    const quotation = this.createQuotationObject(this.quotation.value, this.quotationService.quotationItems);
    quotation.id_cotizr_quotation = this.refQuotation.id_cotizr_quotation;
    const isUpdate = await this.api.updateQuotation(quotation.toDBObject());
    if (isUpdate) {
      this.action.success('Cotizacion Actualizada');
      const quotationObj = (await this.api.getUserQuotations(1));
      if (quotationObj) {
        const updatedQuotation = quotationObj.quotations[0];
        updatedQuotation.items = JSON.parse(updatedQuotation.items);
        updatedQuotation.user = JSON.parse(updatedQuotation.user);
        this.action.stop();
      this.router.navigate(['panel/user-quotations']);
        this.download(new Quotation(updatedQuotation));
      }
    } else {
      this.action.stop();
    }
  }

  descar(quotation) {
    if (quotation) {
      const updatedQuotation = JSON.parse(JSON.stringify(quotation)); // Realizar una copia profunda del objeto quotation
      updatedQuotation.items = JSON.parse(updatedQuotation.items);
      updatedQuotation.user = JSON.parse(updatedQuotation.user);
      this.action.stop();
      this.download(updatedQuotation);
    } else {
      this.action.stop();
    }
  }

  
  download(quotation) {
    this.quotationService.generatePDF(quotation);
  }

 /* calculateDiscount(value) {
    this.discountValue = value;
    this.quotationValue.emit(value);
  }*/
  subtotal() {
    const discountValue = this.quotation.get('discount').value || 0; // Obtener el valor del descuento o establecerlo en 0 si no hay descuento
    let subtotalAmount = BusinessMath.subtotalFromProductArray(
      this.quotationService.quotationItems,
      this.quotation.get('currency').value,
      this.quotation.get('dolar_rate').value,
      this.quotation.get('iva_type').value
    );
    // Aplicar descuento si hay un valor de descuento válido
    if (discountValue > 0) {
      subtotalAmount = subtotalAmount /  (1 + (this.quotation.get('discount').value / 100))
    }
    return subtotalAmount;
  }
  iva() {
    const discountValue = this.quotation.get('discount').value || 0;
    let ivaDescuento = BusinessMath.ivaFromProducts(
      this.quotationService.quotationItems,
      PrestashopInfo.IVA,
      this.quotation.get('currency').value,
      this.quotation.get('dolar_rate').value,
      this.quotation.get('iva_type').value
    );
    if (this.quotation.get('discount').value > 0) {
      ivaDescuento = ivaDescuento /  (1 + (this.quotation.get('discount').value / 100))
    }
    return ivaDescuento;
  }

  total() {
    const discountValue = this.quotation.get('discount').value || 0;
    let totalDesc =BusinessMath.totalIVAFromProductArray(
      this.quotationService.quotationItems,
      PrestashopInfo.IVA,
      this.quotation.get('currency').value,
      this.quotation.get('dolar_rate').value,
      this.quotation.get('iva_type').value
    );

    if (discountValue > 0) {
      totalDesc = totalDesc /  (1 + (this.quotation.get('discount').value / 100))
    }
    return totalDesc;

  }

  getProductImg(id): any {
    return `url(https://officenet.net.ve/cotizaya/images?id=${id})`;
  }

  reset() {
    this.quotationService.quotationItems = [];
    this.quotation.setValue(new Quotation({
      receiver: '',
      company_name: '',
      company_address: '',
      identification: '',
      phone_number: '',
      status: QuotationState.Pendent,
      currency: 'usd',
      dolar_rate: AppStorage.get(StorageKey.DolarRate),
      iva_type: 'normal',
      discount:0
    }));
  }
}
