import { Injectable,EventEmitter  } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogCustomComponent } from 'src/app/components/dialog-custom/dialog-custom.component';
import { ApiService } from './api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private _refresh$ = new Subject <void>();
  public messages: string[] = [];
  public allC :any = [];
  public TotalMessages:number;
  public messageSentEvent: EventEmitter<void> = new EventEmitter<void>();
  constructor(private matDialog: MatDialog ,private api: ApiService) {
  }

  openDialogCustom($id) {
    this.getComments($id).then(messagesArray => {
      const dialogRef = this.matDialog.open(DialogCustomComponent, {
        width: '800px',
        data: { id: $id, messages: messagesArray },
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.messageSentEvent.next();
      // this._refresh$.next();
      });
    });
  }

get refresh$(){
return this._refresh$
  }
  async getComments(id) {
    const data = await this.api.getMessage(id);
    const allComments = data.comments;
    const messagesArray = allComments.map(comment => {
      return {
        message: comment.mensajes,
        createdAt: comment.created_at,
        id_comment: comment.id,
        employe: comment.employee_name
      };
    });
    return messagesArray;
  }
}
/* openDialogCustom($id) {
    this.getComments($id).then(messagesArray => {
      const dialogRef = this.matDialog.open(DialogCustomComponent, {
        width: '800px',
        data: { id: $id, messages: messagesArray },
      });
      dialogRef.afterClosed().subscribe(result => {
        // Acciones después de cerrar el diálogo
      });
    });
  }
  */